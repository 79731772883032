import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const CareInstructionSchema = z.object({
  instruction: z.string(),
  type: z.enum(['washing', 'drying', 'ironing', 'dryCleaning', 'wearAndTear']),
  icon: ImageSchema,
})

export type CareInstruction = z.infer<typeof CareInstructionSchema>

const CARE_INSTRUCTIONS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    defined(careInstructions) => {
      careInstructions[]-> {
        ${coalesceQuery('instruction', locale)},
        type,
        icon {
          ${GET_IMAGE_FRAGMENT()}
        }
      }
    }
  `)

export const CareInstructionSetSchema = z.object({
  washing: z
    .object({
      careInstructions: z.array(CareInstructionSchema),
    })
    .optional(),
  drying: z
    .object({
      careInstructions: z.array(CareInstructionSchema),
    })
    .optional(),
  ironing: z
    .object({
      careInstructions: z.array(CareInstructionSchema),
    })
    .optional(),
  storage: z
    .object({
      careInstructions: z.array(CareInstructionSchema),
    })
    .optional(),
  wearAndTear: z
    .object({
      careInstructions: z.array(CareInstructionSchema),
    })
    .optional(),
})

export type CareInstructionSet = z.infer<typeof CareInstructionSetSchema>

export const CARE_INSTRUCTION_SET_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(washing.careInstructions) => {
    washing {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  defined(drying.careInstructions) => {
    drying {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  defined(ironing.careInstructions) => {
    ironing {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  defined(storage.careInstructions) => {
    storage {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  defined(wearAndTear.careInstructions) => {
    wearAndTear {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  }
`)
