import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceLocalizedValue } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'

export const ACCOUNT_KEYS = [
  'login',
  'register',
  'signIn',
  'signUp',
  'reset',
  'resetPassword',
  'newCustomer',
  'returningCustomer',
  'forgotYourPassword',
  'forgotPassword',
  'yourEmail',
  'password',
  'confirmPassword',
  'activateAccount',
  'submit',
  'saveInfo',
  'changeEmailMessage',
  'currentPassword',
  'newPassword',
  'changePassword',
  'userInfo',
  'personalInfo',
  'orders',
  'streetName',
  'postalCode',
  'city',
  'country',
  'address',
  'saveAddress',
  'checkEmail',
  'passwordsMatchError',
  'passwordChangeSuccess',
  'currentPasswordFailMessage',
  'loginFailMessage',
  'userInfoChangeSuccessMesssage',
  'shippingAddress',
  'billingAddress',
  'orderTotal',
  'tax',
  'shipping',
  'subtotal',
  'delivered',
  'shipped',
  'processing',
  'orderPlaced',
  'trackShipment',
  'order',
  'date',
  'requestReturn',
  'logout',
  'myAccount',
  'forgotPasswordErrorMessage',
  'noOrdersYet',
  'productNoLongerAvailable',
  'invoice',
]

export const AccountDataSchema = z.object({
  login: z.string(),
  register: z.string(),
  signIn: z.string(),
  signUp: z.string(),
  reset: z.string(),
  resetPassword: z.string(),
  newCustomer: z.string(),
  returningCustomer: z.string(),
  forgotYourPassword: z.string(),
  forgotPassword: z.string(),
  yourEmail: z.string(),
  password: z.string(),
  confirmPassword: z.string(),
  activateAccount: z.string(),
  submit: z.string(),
  saveInfo: z.string(),
  changeEmailMessage: z.string(),
  currentPassword: z.string(),
  newPassword: z.string(),
  changePassword: z.string(),
  userInfo: z.string(),
  personalInfo: z.string(),
  orders: z.string(),
  streetName: z.string(),
  postalCode: z.string(),
  city: z.string(),
  country: z.string(),
  address: z.string(),
  saveAddress: z.string(),
  checkEmail: z.string(),
  passwordsMatchError: z.string(),
  passwordChangeSuccess: z.string(),
  currentPasswordFailMessage: z.string(),
  loginFailMessage: z.string(),
  userInfoChangeSuccessMesssage: z.string(),
  shippingAddress: z.string(),
  billingAddress: z.string(),
  orderTotal: z.string(),
  tax: z.string(),
  shipping: z.string(),
  subtotal: z.string(),
  delivered: z.string(),
  shipped: z.string(),
  processing: z.string(),
  orderPlaced: z.string(),
  trackShipment: z.string(),
  order: z.string(),
  date: z.string(),
  requestReturn: z.string(),
  logout: z.string(),
  myAccount: z.string(),
  forgotPasswordErrorMessage: z.string(),
  noOrdersYet: z.string(),
  productNoLongerAvailable: z.string(),
  invoice: z.string(),
  shopNowLink: LinkSchema,
})

export const ACCOUNT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    ${ACCOUNT_KEYS.map((term) => `${coalesceLocalizedValue(term, locale)}`).join(',')},
    shopNowLink-> {
      ${LINK_FRAGMENT(locale)}
    }
`)
