import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from './richText.fragment'

export const ProductSizeInfoNoteSchema = z.object({
  content: z.lazy(() => RichTextSchema).optional(),
})

export type ProductSizeInfoNote = z.infer<typeof ProductSizeInfoNoteSchema>

export const PRODUCT_SIZE_INFO_NOTE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${RICH_TEXT_FRAGMENT(locale, 'content')},
`)
