import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceFilter, coalesceQuery } from '../../utils'

export const RichTextSchema = z.array(
  z
    .object({
      _type: z.string(),
      style: z.string().optional(),
      _key: z.string().optional(),
      children: z.array(
        z.object({
          _type: z.string(),
          _key: z.string().optional(),
          text: z.string(),
          marks: z.array(z.string()).optional(),
        }),
      ),
      markDefs: z
        .array(
          z.object({
            _type: z.string(),
            _key: z.string().optional(),
            href: z.string().optional(),
            reference: z
              .object({
                _type: z.string(),
                _ref: z.string(),
                slug: z
                  .object({
                    current: z.string(),
                  })
                  .optional(),
              })
              .optional(),
          }),
        )
        .optional(),
    })
    .passthrough(),
)

export type RichText = z.infer<typeof RichTextSchema>

export const RICH_TEXT_FRAGMENT = (locale = DEFAULT_LOCALE, name = 'text', rename?: string) =>
  defineQuery(`
  ${coalesceQuery(
    name,
    locale,
    rename,
    `[] {
      ...,
      defined(markDefs) => {
        markDefs[] {
          ...,
          _type == "internalLink" => {
            // We used coalesce here because Products don't have a localized slug
            'slug': coalesce(${coalesceFilter('@.reference->slug', locale)}, @.reference->slug),
            "type": @.reference->_type
          }
        }
      }
    }`,
  )}
`)
