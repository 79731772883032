import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { DEFAULT_OPENING_TIME_FRAGMENT, OpeningTimeSchema } from './sectionStore.fragment'

export const SpecialOpeningTimeSchema = OpeningTimeSchema.omit({ message: true }).extend({
  date: z.string(),
})

export const ThumbnailStoreInfoSchema = z.object({
  city: z.string(),
  street: z.string(),
  postalCode: z.string(),
  country: z.string(),
  googleMapsLink: z.string().optional(),
  isOpen: z.boolean(),
  defaultOpeningTimes: z.object({
    monday: OpeningTimeSchema,
    tuesday: OpeningTimeSchema,
    wednesday: OpeningTimeSchema,
    thursday: OpeningTimeSchema,
    friday: OpeningTimeSchema,
    saturday: OpeningTimeSchema,
    sunday: OpeningTimeSchema,
  }),
  specialOpeningTimes: z.array(SpecialOpeningTimeSchema),
})

export const StoreObjectSchema = z.object({
  store: ThumbnailStoreInfoSchema,
  page: z.object({
    slug: SlugSchema,
  }),
  image: MediaSchema,
})

export const SectionStoreIndexSchema = z.object({
  _type: z.literal('sectionStoreIndex'),
  _id: z.string(),
  title: z.string(),
  todaysOpeningHours: z.string(),
  viewStoreDetails: z.string(),
  stores: z.array(StoreObjectSchema),
})

export type SpecialOpeningTime = z.infer<typeof SpecialOpeningTimeSchema>
export type OpeningTime = z.infer<typeof OpeningTimeSchema>
export type ThumbnailStoreInfo = z.infer<typeof ThumbnailStoreInfoSchema>
export type StoreObject = z.infer<typeof StoreObjectSchema>
export type SectionStoreIndexData = z.infer<typeof SectionStoreIndexSchema>

export const SECTION_STORE_INDEX_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
_type == 'sectionStoreIndex' => {
  _type,
  _id,
  ${coalesceQuery('title', locale)},
  ${coalesceQuery('todaysOpeningHours', locale)},
  ${coalesceQuery('viewStoreDetails', locale)},
  stores[] {
    store-> {
      ${coalesceQuery('city', locale)},
      street,
      postalCode,
      defined(googleMapsLink) => {googleMapsLink},
      country,
      ${DEFAULT_OPENING_TIME_FRAGMENT(locale)},
      isOpen,
      specialOpeningTimes[]-> {
        date,
        closed,
        defined(open) => { open },
        defined(close) => { close },
      }
    } {
      ...,
      "specialOpeningTimes": specialOpeningTimes[dateTime(date + 'T00:00:00Z') < dateTime(now()) + 60*60*24*14]
    },
    page-> {
      ${coalesceQuery('slug', locale)}
    },
    image-> {
       ${MEDIA_FRAGMENT()}
    }
  } | order(store.city asc)
}
`)
