import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceLocalizedValue } from '../../utils'

export const StylesOrderSchema = z.object({
  applyOrderTo: z
    .object({
      header: z.boolean().optional(),
      footer: z.boolean().optional(),
      collectionSlider: z.boolean().optional(),
      pcpCollectionCards: z.boolean().optional(),
      sizeCharts: z.boolean().optional(),
    })
    .optional(),
  styles: z.array(z.string()).optional(),
})

export const STYLES_ORDER_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  *[_type == 'stylesOrder'][0] {
    applyOrderTo,
    ${coalesceLocalizedValue('stylesOrder', locale, 'styles', '[]->._id')},
  }
`)
