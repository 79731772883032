import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const ModelBioSchema = z.object({
  _type: z.literal('modelBio'),
  modelName: z.string(),
  modelHeight: z.number(),
  modelTopSize: z.enum(['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL']).optional(),
  modelTrouserSize: z
    .enum([
      '28/30',
      '28/32',
      '28/34',
      '30/30',
      '30/32',
      '30/34',
      '31/30',
      '31/32',
      '31/34',
      '32/30',
      '32/32',
      '32/34',
      '33/30',
      '33/32',
      '33/34',
      '34/30',
      '34/32',
      '34/34',
      '36/30',
      '36/32',
      '36/34',
      '38/30',
      '38/32',
      '38/34',
      '40/30',
      '40/32',
      '40/34',
    ])
    .optional(),
  modelShortsSize: z.enum(['XS/28', 'S/30', 'M/32', 'L/34', 'XL/36', 'XXL/38', '3XL/40']).optional(),
})

export type ModelBio = z.infer<typeof ModelBioSchema>

export const MODEL_BIO_FRAGMENT = () =>
  defineQuery(`
  _type,
  modelName,
  modelHeight,
  defined(modelTopSize) => { modelTopSize },
  defined(modelTrouserSize) => { modelTrouserSize },
  defined(modelShortsSize) => { modelShortsSize },
`)
