import { defineQuery } from 'next-sanity'
import z from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const ServiceUspsSchema = z.object({
  serviceUsps: z.array(
    z.object({
      icon: z.enum(['tick', 'warning']),
      text: RichTextSchema,
      openAccordionItem: z.enum(['description', 'sizing', 'material', 'details', 'policies']).optional(),
    }),
  ),
})
export type ServiceUspSet = z.infer<typeof ServiceUspsSchema>

export const SERVICE_USPS_SET_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  serviceUsps[]-> {
    icon,
    ${RICH_TEXT_FRAGMENT(locale, 'text')},
    defined(openAccordionItem) => {
      openAccordionItem
    }
  }
`)
