import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { COLOR_FRAGMENT, ColorSchema } from './color.fragment'

export const ProductColorSchema = z.object({
  _id: z.string(),
  slug: SlugSchema,
  title: z.string(),
  productColor: ColorSchema,
})

export type ProductColor = z.infer<typeof ProductColorSchema>

export const PRODUCT_COLOR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  slug,
  title,
  "productColor": color-> {
    ${COLOR_FRAGMENT(locale)}
  },
`)
