import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceFilter, coalesceLocalizedValue, coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT } from '../common/getImage.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const OpeningTimeSchema = z.object({
  open: z.string().optional(),
  close: z.string().optional(),
  closed: z.boolean(),
  message: z.string().optional(),
})

const SpecialOpeningTimeSchema = OpeningTimeSchema.omit({ message: true }).extend({
  date: z.string(),
  occasion: z.string().optional(),
})

export const RetailStoreDataSchema = z.object({
  shopifyId: z.string(),
  geoPoint: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  city: z.string(),
  street: z.string(),
  postalCode: z.string(),
  country: z.string(),
  googleMapsLink: z.string().optional(),
  telephoneNumber: z.string().optional(),
  isOpen: z.boolean(),
  defaultOpeningTimes: z.object({
    monday: OpeningTimeSchema,
    tuesday: OpeningTimeSchema,
    wednesday: OpeningTimeSchema,
    thursday: OpeningTimeSchema,
    friday: OpeningTimeSchema,
    saturday: OpeningTimeSchema,
    sunday: OpeningTimeSchema,
  }),
  specialOpeningTimes: z.array(SpecialOpeningTimeSchema),
  accessibility: z
    .array(
      z.object({
        text: RichTextSchema,
        icon: z.any(),
      }),
    )
    .optional(),
})

export type RetailStoreData = z.infer<typeof RetailStoreDataSchema>

export const SectionStoreSchema = z.object({
  _type: z.literal('sectionStore'),
  _id: z.string(),
  title: z.string(),
  store: RetailStoreDataSchema,
  description: RichTextSchema.optional(),
  storeMedia: MediaSchema,
  recruitmentMessage: RichTextSchema.optional(),
})

export type SectionStoreData = z.infer<typeof SectionStoreSchema>

export const DEFAULT_OPENING_TIME_DAY_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    closed,
    defined(open) => {open},
    defined(close) => {close},
    defined(message) => {
      ${coalesceQuery('message', locale)}
    }
`)

export const DEFAULT_OPENING_TIME_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
   defaultOpeningTimes {
    monday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
    tuesday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
    wednesday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
    thursday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
    friday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
    saturday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
    sunday {
      ${DEFAULT_OPENING_TIME_DAY_FRAGMENT(locale)}
    },
  }
`)

export const SECTION_STORE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionStore' => {
    _type,
    _id,
    store-> {
      geoPoint,
      ${coalesceQuery('city', locale)},
      street,
      country,
      postalCode,
      defined(googleMapsLink) => {googleMapsLink},
      defined(telephoneNumber) => {telephoneNumber},
      ${DEFAULT_OPENING_TIME_FRAGMENT(locale)},
      isOpen,
      specialOpeningTimes[dateTime(@->date + 'T00:00:00Z') > dateTime(now())]-> {
        date,
        closed,
        defined(open) => { open },
        defined(close) => { close },
        defined(occasion) => {
          "occasion": occasion-> {
            ${coalesceQuery('title', locale)},
          }.title,
        }
      } | order(date, asc),
      defined(accessibility) => {
        accessibility[]-> {
          ${RICH_TEXT_FRAGMENT(locale, 'text')},
          icon {
            ${GET_IMAGE_FRAGMENT()}
          },
        }
      }
    },
    ${coalesceQuery('title', locale)},
    defined(description) => {
      ${RICH_TEXT_FRAGMENT(locale, 'description')}
    },
    storeMedia-> {
      ${MEDIA_FRAGMENT()}
    },
    defined(recruitmentMessage) => {
      ${coalesceLocalizedValue(
        'recruitmentMessage',
        locale,
        '',
        `[] {
              ...,
              markDefs[] {
                ...,
                _type == "internalLink" => {
                  // We used coalesce here because Products don't have a localized slug
                  'slug': coalesce(${coalesceFilter('@.reference->slug', locale)}, @.reference->slug),
                  "type": @.reference->_type
                }
              }
            }`,
      )},
    }
  }
`)
