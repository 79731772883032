import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceDefinedQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const SeoSchema = z.object({
  seoTitle: z.string().optional(),
  seoDescription: z.string().optional(),
  seoImage: ImageSchema.optional(),
  noIndex: z.boolean().optional(),
})

export type Seo = z.infer<typeof SeoSchema>

export const SEO_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${coalesceDefinedQuery('seoTitle', locale)},
  ${coalesceDefinedQuery('seoDescription', locale)},
  defined(seoImage) => {
    "seoImage": seoImage {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(noIndex) => {
    noIndex
  },
`)
